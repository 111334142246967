import axiosInstance from "../axiosInstance"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Modals = ({ setModalSelection, selectedLot }) => {
    const [modalList, setModalList] = useState()
    const navigate = useNavigate();
    
    const modalSelect = (data) => {
        setModalSelection(data);
        navigate("/options");
        window.scrollTo(0,0);
    };

    useEffect(() => {
        if(!selectedLot){
            navigate("/");
            window.scrollTo(0,0);
        }

        axiosInstance.
            get("/modal")
            .then((res) => {
                setModalList(res.data.data)
            })
    }, [])

    return (
        <div className="mt-5">
            <div className="text-center mb-7">
                <h2 className="text-3xl w-fit m-auto font-semibold my-3">Pick the Perfect Model for Your New Home</h2>
                <p>Every dream home starts with a great design. Explore our options of expertly designed models, each customizable to fit your needs and style.</p>
                <p>Select your favourite model and begin designing your future.</p>
            </div>
            {modalList && selectedLot &&
            <div className="w-4/5 m-auto grid grid-cols-3 gap-10 lg:w-3/5 lg:gap-16 sm:grid-cols-3">

                {modalList.filter((modal) => {
                    for(let i = 0; i < selectedLot.comp_modals.length; i++){
                        if(selectedLot.comp_modals[i] === modal.name){
                            return true;
                        }
                    }
                    return false;
                }).map((data, index) => (
                        <div className="w-full hover:cursor-pointer h-1/3 col-span-1 last:col-start-2"  key={index} onClick={() => (modalSelect(data))}>
                            <img src={data.image} alt={data.name} />
                            <div className="w-full h-10 flex items-center justify-center bg-v-green text-white">{data.name}</div>
                        </div>
                ))}
            </div>}

        </div>
    )
}
export default Modals;