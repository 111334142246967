import { useNavigate } from "react-router-dom";
const Header = ({onstart}) => {
    const navigate = useNavigate()
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="px-8 pt-8 h-[176px]">
            <div className="flex-row justify-between items-center w-max">
                <a href="https://www.verandalegacy.ca/" target="_blank" rel="noreferrer" className="w-max block"><img src={"../Veranda-at-Legacy_logo_green_rgb.png"} alt="Veranda Logo" className="md:max-h-30 max-h-24" /></a>
                {!onstart &&
                <div id="back-button" className="sm:m-auto w-1/2 mt-10 sm:mt-10 h-8 text-white hover:cursor-pointer bg-v-green flex justify-center items-center" onClick={back}><p className="w-full h-full flex justify-center items-center">Back</p></div>
                }
            </div>
        </div>
    )
}
export default Header;