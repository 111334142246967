import React, { useEffect, useState } from 'react'

const OptionSelect = ({ data, level, floor, setImg, setaddCost, selectedOptions, setSelectedOptions }) => {
    // const [ selectedOptions, setSelectedOptions ] = useState([{}])
    const [collapsed, setCollapsed] = useState(collapse);
    const [arrow, setArrow] = useState("0deg");
    const [loading, setLoading] = useState(true);
    const [ numOfSelected, setNumOfSelected] = useState(0);

    const changeCollapsed = () => {
        if (collapsed === collapse) {
            setCollapsed(show);
            setArrow("180deg");
        }
        else {
            setCollapsed(collapse);
            setArrow("0deg");
        };
    };

    const manageSelection = (e) => {
        const option = JSON.parse(e.value);
        if(e.checked === true){
            setNumOfSelected(prev => prev + 1);
        }
        else if(e.checked === false){
            setNumOfSelected(prev => prev - 1);
        }
        // console.log(e.checked)
        setImg(option.image)
        for( let i = 0; i< selectedOptions.length; i++){
            if(selectedOptions[i].desc === option.desc){
                setaddCost(prev => prev - option.cost)
                setSelectedOptions(prev => prev.filter((elem) => elem.desc !== option.desc))
                return;
            };
        };
        setaddCost(prev => prev + option.cost);
        setSelectedOptions(prev => [...prev, option]);
        setImg(option.image);
    };  

    const standardSelect = (e) => {
        // e = JSON.parse(e);
        const val = JSON.parse(e.target.value);
        // const checked = e.target.checked
        // console.log(val)
        // console.log(checked)
        // console.log(selectedOptions);
        setImg(val.image) 
        // console.log(val)
        if(level !== "Exterior"){
            setNumOfSelected(0);
            setaddCost(0)
            for(let i =0; i < selectedOptions.length; i++){
                document.getElementById(selectedOptions[i].desc).checked = false;
                
            }
            setSelectedOptions([])
        }
        
    }
    
    useEffect(() => {
        setLoading(false);
    }, [])

    return (
        <div id='selection-wrapper' className='border  rounded mb-2 w-full'>
            {loading ? (
                <div> loading</div>
            ) : (
                <>
            <div className='flex justify-between items-center font-semibold w-full py-[5px] px-3 border-b  hover:cursor-pointer bg-v-cream' onClick={changeCollapsed}>
                <p>{floor}</p>
                <div id='icon-wrapper' className='w-4 h-5' style={{ rotate: arrow, transition: "all .3s ease-out" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        {/* <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                        <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                </div>
            </div>
            <div id={floor} className="overflow-hidden" style={collapsed}>
                <div id='content-wrapper' className='accent-black flex justify-between pl-3 min-w-fit'>
                    <div id='input-wrapper' className='my-3 flex-col' >
                        {level !== "Exterior" &&
                            <div id='label-wrapper' className='flex-wrap items-center mb-[2px]'>
                                <input className='accent-black mr-5 w-4 h-4' type="checkbox" id={"standard" + floor} checked={numOfSelected < 1} name={floor} value={JSON.stringify(level)} onChange={(e = {}) => standardSelect(e)}/>
                                <label htmlFor={"standard" + floor} className='h-5 text-nowrap'>{level.name}</label><br />
                            </div>}
                            
                        {data.options.map((option, index) => (
                            option.level === level.level &&
                            <div key={index} id='label-wrapper' className='flex-wrap items-center mb-[2px] '>
                                <input className='accent-black mr-5 w-4 h-4 ' type="checkbox" id={option.desc} value={JSON.stringify(option)} onClick={(e) => manageSelection(e.target)} />
                                <label htmlFor={option.desc} className='h-5 text-nowrap' >{option.desc} (+{"$" + option.cost})</label><br />
                            </div>
                        ))}

                        {data.options.map((option, index) => (
                            option.level === level &&
                            <div key={index} id='label-wrapper' className='flex-wrap items-center mb-[2px] '>
                                <input className='accent-black mr-5 w-4 h-4' type="checkbox" id={option.desc} value={JSON.stringify(option)} onClick={(e) => manageSelection(e.target)} />
                                <label htmlFor={option.desc} className='h-5 text-nowrap' >{option.desc} (+{"$" + option.cost})</label><br />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </>)}
        </div>
    );
};

export default OptionSelect;

const show = {
    maxHeight: "200px",
    transition: "all .5s ease",
    minHeight: "fit-content"
};
const collapse = {
    maxHeight: '0px',
    transition: "all .4s ease",
};