import {useNavigate} from "react-router-dom";

const Start = () => {
    const navigate = useNavigate();
    const startBuild = () => { 
        navigate("/lots")
    }
    return(
        <div className="w-full h-[50vh] flex-row justify-items-center">
            
            <div className="bg-v-green bg-opacity-85 m-10 p-3 border rounded-lg text-center text-white w-3/5">
                <h3 className="font-semibold text-3xl opacity-100"> Your Dream Home Just a Click Away</h3>
                <p className="text-lg font-normal text-slate-200">Ready to turn your vision into reality? With our expert team building your perfect home has never been easier. From concept to completion, we'll guide you every step of the way. Click below to begin your journey towards the home you've always imagined.</p>
            </div>
            <button onClick={startBuild} className="min-w-30 w-auto h-auto bg-v-green text-white py-1 px-2 text-lg align-middle">Start Building Your Home</button>
        </div>
    )
}

export default Start;