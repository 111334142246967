import React from 'react'
import format from '../lib/formatPrice';
const TotalPrice = ({ modelPrice = 0, optionsCost }) => {

    return (
        <div className='border border-gray-400 w-max h-10 p-2  text-xl rounded-sm bg-white z-50'>
            <p>Estimated Price: {format(modelPrice + optionsCost)}</p>
        </div>
    )
}

export default TotalPrice